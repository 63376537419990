import { useMutation } from "react-query";
import { deleteMedia } from "../api";
import { useToken } from "./useToken";

export const useDeleteUpload = () => {
  const { token } = useToken();
  const mutation = useMutation(deleteMedia);

  return ({ id }) =>
    new Promise((resolve, reject) => {
      return mutation
        .mutateAsync({ token, id })
        .then((data) => resolve(data))
        .catch((error) => {
          reject(error);
        });
    });
};
