/**
 * Retrieve variables (ID and template) from a template.
 * @param template
 * @returns {{template: *, match: *, id: *}[]}
 */
const getVariables = (template) => {
  return [
    ...new Set(
      [...template.matchAll(/\[([a-zA-Z-]+)(?::([a-zA-Z-]+))?\]/g)].map(
        ([match, id, renderer]) => ({ id, renderer, match })
      )
    ),
  ];
};

/**
 * Retrieve configuration for a given variable ID.
 * @param configuration
 * @param variable
 */
const getVariableConfiguration = (configuration, variable) =>
  configuration.variables[variable];

/**
 * Retrieve values for a given variable ID.
 * @param values
 * @param variable
 */
const getVariableValues = (values, variable) => values[variable];

/**
 * Populate a template, given configuration and values.
 * @param configuration
 * @param template
 * @param values
 * @returns {*}
 */
export const populate = ({ configuration, template, values }) => {
  const variables = getVariables(template);
  let instance = template;

  for (const variable of variables) {
    const { renderer } = getVariableConfiguration(configuration, variable.id);
    const render = variable.renderer ? renderer[variable.renderer] : renderer;

    const value = getVariableValues(values, variable.id);
    const replacement = Array.isArray(value)
      ? value
          .map((vals, index) => {
            let selected = render;

            if (index === 0 && render.first) {
              selected = render.first;
            } else if (render.first) {
              selected = render.main;
            }

            return interpolate(selected, { ...vals, n: index + 1 });
          })
          .join("")
      : interpolate(render, value);

    instance = instance.replace(variable.match, replacement);
  }
  return instance;
};

const interpolate = (template = "", values) => {
  const variables = getVariables(template);
  let instance = template;

  for (const variable of variables) {
    instance = instance.replace(variable.match, values?.[variable.id]);
  }

  return instance;
};
