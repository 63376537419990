import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../hooks";
import { useFormik } from "formik";
import { Breadcrumbs, Button, LoadingOverlay } from "../../components";
import { Image, Text, Textarea, Colour, Logo } from "./formatters";
import { populate } from "../../utilities/template";
import { Parser } from "html-to-react";
import { Media } from "./Media";
import { Html } from "./Html";
import { XIcon } from "@heroicons/react/solid";

export const Configuration = () => {
  const { id } = useParams();

  const {
    getConfiguration: { data: configuration, isLoading, isSuccess, refetch },
    updateConfiguration,
    isUpdateConfigurationLoading,
  } = useConfiguration(id);

  const handleSubmit = (values) => {
    if (hasChanged) {
      const temporary = { ...values };
      const name = temporary.name;
      delete temporary.name;
      return updateConfiguration({ id, name, values: temporary }).then(refetch);
    }

    return Promise.resolve();
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => handleSubmit(values).then(() => setOpenHtml(true)),
  });

  useEffect(() => {
    if (isSuccess) {
      formik.setValues({ name: configuration.name, ...configuration.values });
      setIsReady(true);
    }
  }, [isSuccess]);

  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(false);
  const [openHtml, setOpenHtml] = useState(false);
  const [onSelect, setOnSelect] = useState(() => {});

  const getVariables = () =>
    Object.entries(configuration.template.configuration.variables).map(
      ([id, value]) => ({
        id,
        ...value,
      })
    );

  const getFieldComponent = (field) => {
    const type = field.type || "text";

    const mappings = {
      text: Text,
      textarea: Textarea,
      image: Image,
      colour: Colour,
      logo: Logo,
    };

    return mappings[type];
  };

  const hasChanged =
    isSuccess &&
    JSON.stringify(formik.values) !==
      JSON.stringify({ name: configuration.name, ...configuration.values });

  useEffect(() => {
    const id = setInterval(() => {
      if (hasChanged) {
        return handleSubmit(formik.values);
      }
    }, 30000);

    return () => {
      clearInterval(id);
    };
  }, [hasChanged, formik]);

  if (isLoading || !isSuccess || !isReady) {
    return <LoadingOverlay open={true} />;
  }

  const parser = new Parser();

  const components = parser.parse(
    populate({
      configuration: configuration.template.configuration,
      template: configuration.template.template,
      values: formik.values,
    })
  );

  return (
    <>
      <Media open={open} setOpen={setOpen} onSelect={onSelect} logo={logo} />
      <div className="pb-4 bg-white border-b border-grey-500">
        <Breadcrumbs>
          <Breadcrumbs.Item to={`/template/${configuration.template.id}`}>
            {configuration.template.name}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to="#">Configuration</Breadcrumbs.Item>
        </Breadcrumbs>
      </div>
      <div>
        <Html
          open={openHtml}
          setOpen={setOpenHtml}
          html={populate({
            configuration: configuration.template.configuration,
            template: configuration.template.template,
            values: formik.values,
          })}
        />
        <div className="flex flex-grow overflow-hidden sm:h-[calc(100vh-7.4rem)] h-full sm:flex-row flex-col">
          <form
            className="sm:max-w-sm w-full flex-grow bg-white sm:border-r sm:border-gray-200 flex flex-col justify-between"
            aria-label="Sidebar"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex-grow overflow-y-scroll">
              <div className="p-4">
                <div className="mt-4 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                  <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                    Configuration Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  />
                </div>
              </div>
              {getVariables().map((variable, index) => {
                return (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="w-full p-4 border-b border-gray-200 flex w-full items-center">
                          <div className="flex flex-col text-left flex-grow">
                            <div className="font-medium text-sm">
                              {variable.label}
                            </div>
                            {variable.sublabel && (
                              <div className="text-gray-600 text-sm">
                                {variable.sublabel}
                              </div>
                            )}
                          </div>
                          <div>
                            {open ? (
                              <ChevronUpIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <div className="p-4">
                            {variable.repeater && (
                              <div>
                                {formik.values[variable.id].map(
                                  (value, valueIndex) => (
                                    <div
                                      className="mb-1 last:mb-0"
                                      key={valueIndex}
                                    >
                                      <div className="flex items-center">
                                        <div className="flex-grow py-4">
                                          {Object.entries(variable.fields).map(
                                            ([id, field], index) => {
                                              const Component =
                                                getFieldComponent(field);
                                              return (
                                                <Component
                                                  key={index}
                                                  label={field.label}
                                                  value={
                                                    formik.values[variable.id][
                                                      valueIndex
                                                    ][id]
                                                  }
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `${variable.id}[${valueIndex}]`,
                                                      {
                                                        ...formik.values[
                                                          variable.id
                                                        ][valueIndex],
                                                        [id]: value,
                                                      }
                                                    );
                                                  }}
                                                  onClick={() => {
                                                    setOpen(true);
                                                    setLogo(false);
                                                    setOnSelect(
                                                      () => (value) => {
                                                        setOpen(false);
                                                        formik.setFieldValue(
                                                          `${variable.id}[${valueIndex}]`,
                                                          {
                                                            ...formik.values[
                                                              variable.id
                                                            ][valueIndex],
                                                            [id]: value,
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }}
                                                />
                                              );
                                            }
                                          )}
                                        </div>
                                        <button
                                          type="button"
                                          className="ml-2 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 border-none text-gray-700 bg-gray-100 hover:bg-gray-200 focus:ring-indigo-500"
                                          disabled={
                                            variable.min
                                              ? formik.values[variable.id]
                                                  .length <= variable.min
                                              : false
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            const newValues =
                                              formik.values[variable.id];
                                            newValues.splice(valueIndex, 1);

                                            formik.setFieldValue(
                                              variable.id,
                                              newValues
                                            );
                                          }}
                                        >
                                          <XIcon className="h-4 w-4" />
                                        </button>
                                      </div>
                                      <div>
                                        {valueIndex ===
                                          formik.values[variable.id].length -
                                            1 && (
                                          <button
                                            type="button"
                                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            disabled={
                                              variable.max
                                                ? formik.values[variable.id]
                                                    .length >= variable.max
                                                : false
                                            }
                                            onClick={(e) => {
                                              e.preventDefault();
                                              formik.setFieldValue(
                                                `${variable.id}[${
                                                  formik.values[variable.id]
                                                    .length
                                                }]`,
                                                {
                                                  ...Object.keys(
                                                    variable.fields
                                                  ).reduce(
                                                    (acc, id) => ({
                                                      ...acc,
                                                      [id]:
                                                        variable.fields[id]
                                                          .default || "",
                                                    }),
                                                    {}
                                                  ),
                                                }
                                              );
                                            }}
                                          >
                                            Add{" "}
                                            {variable.repeaterLabel ||
                                              "field group"}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {!variable.repeater && (
                              <div>
                                {Object.entries(variable.fields).map(
                                  ([id, field], index) => {
                                    const Component = getFieldComponent(field);
                                    return (
                                      <Component
                                        key={index}
                                        label={field.label}
                                        value={
                                          formik.values?.[variable.id]?.[id]
                                        }
                                        onChange={(value) => {
                                          formik.setFieldValue(variable.id, {
                                            ...formik.values?.[variable.id],
                                            [id]: value,
                                          });
                                        }}
                                        onClick={() => {
                                          setOpen(true);
                                          setLogo(true);
                                          setOnSelect(() => (url, height) => {
                                            setOpen(false);
                                            formik.setFieldValue(variable.id, {
                                              ...formik.values?.[variable.id],
                                              [id]: `<img src="${url}" alt="Company Logo" style="width: auto; max-height: ${
                                                height || 70
                                              }px;" />`,
                                            });
                                          });
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                );
              })}
            </div>

            <div className="p-4 border-t border-gray-200 flex justify-between items-center">
              <div className="text-gray-500 text-xs font-medium">
                {hasChanged ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    Not saved
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Saved
                  </span>
                )}
              </div>
              <div>
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <Button type="submit">
                    {isUpdateConfigurationLoading && (
                      <svg
                        className="animate-spin -ml-1 mr-2 h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {isUpdateConfigurationLoading ? "Saving..." : "Save"}
                  </Button>
                </span>
              </div>
            </div>
          </form>
          <div className="flex-grow bg-white overflow-y-scroll">
            {components}
          </div>
        </div>
      </div>
    </>
  );
};
