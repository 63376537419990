import { useMutation, useQuery } from "react-query";
import { useToken } from "./useToken";
import { getUser, updateUser, addKey } from "../api";
import { refreshToken } from "../api";

export const useAccount = () => {
  const {
    token,
    user: { id },
    setToken,
  } = useToken();

  const user = useQuery("getUser", () => getUser({ token, id }));

  const mutation = useMutation(updateUser);
  const update = ({ firstName, lastName, username }) =>
    new Promise((resolve, reject) => {
      return mutation
        .mutateAsync({ token, id, firstName, lastName, username })
        .then(async () => {
          await refreshToken({ token }).then(setToken);
          resolve();
        })
        .catch((error) => reject(error));
    });

  const addKeyMutation = useMutation(addKey);
  const add = ({ combination }) =>
    addKeyMutation.mutateAsync({ token, combination });

  return { user, update, addKey: add };
};
