export default {
  translation: {
    navigation: {
      templates: "Templates",
      media: "Media",
      help: "Help",
      account: "My Account",
      licence: "Add Licence",
      signout: "Sign Out",
    },
    heros: {
      templates: {
        title: "Your Templates",
        subtitle: "Here's the templates you've purchased",
        licensed: "Licensed to",
      },
      media: {
        title: "Your Media",
        subtitle: "Manage and upload images to use in your templates",
        files: "Choose files",
        limit: "Increase limit",
      },
    },
    templates: {
      new: "New",
      added: "Added",
    },
    media: {
      breadcrumb: "Your Media",
      delete: "Delete",
    },
  },
};
