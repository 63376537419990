export const authentication = (state, action) => {
  switch (action.type) {
    case "setToken":
      return {
        ...state,
        token: action.payload.token,
      };
    case "setRedirect":
      return {
        ...state,
        redirect: action.payload.redirect,
      };
    case "setRecentlyExpired":
      return {
        ...state,
        recentlyExpired: action.payload.expired,
      };
    default:
      return state;
  }
};
