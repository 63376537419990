import { useMemo, useReducer } from "react";
import Cookies from "js-cookie";
import { authentication } from "../reducers";

const initialState = {
  token: null,
  redirect: null,
  recentlyExpired: false,
};

export const useAuthentication = () => {
  const token = Cookies.get("TOKEN");

  const [state, dispatch] = useReducer(authentication, {
    ...initialState,
    token,
  });
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return { state, dispatch, contextValue };
};
