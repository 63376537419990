export default {
  translation: {
    navigation: {
      templates: "Plantillas",
      media: "Medios",
      help: "Ayuda",
      account: "Mi Cuenta",
      licence: "Agregar Licencia",
      signout: "Cerrar Sesión",
    },
    heros: {
      templates: {
        title: "Tus Plantillas",
        subtitle: "Estas son las plantillas que has comprado",
        licensed: "Licenciado a",
      },
      media: {
        title: "Tus Medios",
        subtitle: "Administre y cargue imágenes para usar en sus plantillas",
        files: "Seleccionar archivos",
        limit: "aumentar el límite ",
      },
    },
    templates: {
      new: "Nueva",
      added: "Añadido",
    },
    media: {
      breadcrumb: "Tus Medios",
      delete: "Eliminar",
    },
  },
};
