import { useMutation } from "react-query";
import { duplicateConfiguration } from "../api";
import { useToken } from "./useToken";

export const useDuplicateConfiguration = () => {
  const { token } = useToken();
  const duplicateMutation = useMutation(duplicateConfiguration);

  return (id) =>
    duplicateMutation.mutateAsync({
      id,
      token,
    });
};
