import { useMutation } from "react-query";
import { register } from "../api";

export const useRegistration = () => {
  const mutation = useMutation(register);

  const registerPromise = ({
    firstName,
    lastName,
    email,
    username,
    password,
    combination,
  }) =>
    new Promise((resolve, reject) => {
      return mutation
        .mutateAsync({
          firstName,
          lastName,
          email,
          username,
          password,
          combination,
        })
        .then((success) => resolve(success))
        .catch((error) => reject(error));
    });

  return { register: registerPromise, isLoading: mutation.isLoading };
};
