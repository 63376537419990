import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useError, useRegistration, useSignIn } from "../../hooks";
import { Checkbox, Error, Input, MaskedInput } from "../../components";

export const Register = () => {
  const { register, isLoading } = useRegistration();
  const { signIn } = useSignIn();
  const { error, setError } = useError();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmPassword: "",
      combination: "",
      terms: false,
    },
    validateOnMount: false,
    validate: (values) => {
      let errors = {};

      if (!values.firstName.length) {
        errors.firstName = "Please specify a valid first name.";
      }

      if (!values.lastName.length) {
        errors.lastName = "Please specify a valid last name.";
      }

      if (!values.username.length) {
        errors.username = "Please specify a valid username.";
      }

      if (!values.email.length) {
        errors.email = "Please specify an email address.";
      }

      if (!values.password.length) {
        errors.password = "Please specify a password.";
      }

      if (
        values.password !== values.confirmPassword ||
        !values.password.length
      ) {
        errors.confirmPassword = "Please confirm your password.";
      }

      if (!values.combination.length) {
        errors.combination = "Please specify a licence key.";
      }

      if (values.terms !== true) {
        errors.terms = "You must agree to our terms and conditions to proceed.";
      }

      return errors;
    },
    onSubmit: (values) =>
      register(values)
        .then(() =>
          signIn({
            email: values.email,
            password: values.password,
          })
        )
        .catch((err) => setError(err.message)),
  });

  return (
    <>
      <Error>{error}</Error>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Sign Up
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Create an account with us to manage your template configurations
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <Input
                    label="First name"
                    id="firstName"
                    name="firstName"
                    autoComplete="first-name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={touched.firstName && errors.firstName}
                  />
                </div>

                <div className="sm:col-span-3">
                  <Input
                    label="Last name"
                    id="lastName"
                    name="lastName"
                    autoComplete="last-name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={touched.lastName && errors.lastName}
                  />
                </div>
              </div>

              <div>
                <Input
                  label="eBay username"
                  id="username"
                  name="username"
                  autoComplete="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  error={touched.username && errors.username}
                />
              </div>

              <div>
                <Input
                  label="Email address"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email}
                />
              </div>

              <div>
                <div className="mt-1">
                  <Input
                    label="Password"
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password && errors.password}
                  />
                </div>
              </div>

              <div>
                <Input
                  label="Confirm password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  error={touched.confirmPassword && errors.confirmPassword}
                />
              </div>

              <div>
                <MaskedInput
                  label="Licence key"
                  id="combination"
                  name="combination"
                  type="text"
                  mask="****-****-****-****-****"
                  autoComplete="combination"
                  onChange={(e) => {
                    setFieldValue("combination", e.target.value.toUpperCase());
                  }}
                  onBlur={handleBlur}
                  value={values.combination}
                  error={touched.combination && errors.combination}
                />
              </div>

              <div className="flex justify-center py-5">
                <Checkbox
                  id="terms"
                  name="terms"
                  onChange={(e) => setFieldValue("terms", e.target.checked)}
                  checked={values.terms}
                  error={touched.terms && errors.terms}
                >
                  <span className="text-gray-500">
                    {" "}
                    I have read and agree to the{" "}
                    <Link
                      to="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-800 underline"
                    >
                      Terms and Conditions
                    </Link>{" "}
                    of this service.
                  </span>
                </Checkbox>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo"
                  disabled={isLoading && "disabled"}
                >
                  {isLoading && (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  )}
                  {isLoading ? "Creating Account..." : "Create Account"}
                </button>
              </div>

              <div className="flex items-center justify-center">
                <div className="text-sm mt-2">
                  <Link
                    to="/signin"
                    className="font-medium text-indigo-600 hover:text-indigo"
                  >
                    Already got an account?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
