import React from "react";
import { PhotographIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

const Logo = ({ label, value, onChange = () => {}, onClick }) => {
  return (
    <div className="relative">
      <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
        {label}
      </label>
      <input
        type="text"
        placeholder="https://i.imgur.com/i/abcd"
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <button
          type="button"
          className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-600 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-gray-70 focus:ring-indigo-500"
          onClick={onClick}
        >
          <PhotographIcon className="h-4 w-4 text-gray-600" />
        </button>
      </div>
    </div>
  );
};

Logo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export { Logo };
