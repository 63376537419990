import axios from "axios";

export const uploadMedia = ({ token, file, onUploadProgress = () => {} }) => {
  const data = new FormData();
  data.append("image", file);

  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/images/upload`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (e) =>
        onUploadProgress(Math.round((e.loaded * 100) / e.total)),
    })
    .then((response) => response.data);
};

export const getMedia = ({ token, page = 1 }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/images?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);

export const getMediaLimit = ({ token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/images/limit`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.limit);

export const deleteMedia = ({ token, id }) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/images/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => true);
