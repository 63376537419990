import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import Configuration from "./pages/Configuration";
import Media from "./pages/Media";
import Template from "./pages/Template";
import Home from "./pages/Home";

export const routes = [
  {
    title: "Home",
    path: "/",
    component: Home,
    authenticated: true,
  },
  {
    title: "Template",
    path: "/template/:id",
    component: Template,
    authenticated: true,
  },
  {
    title: "Configuration",
    path: "/configuration/:id",
    component: Configuration,
    authenticated: true,
  },
  {
    title: "Media",
    path: "/media",
    component: Media,
    authenticated: true,
  },
  {
    title: "Purchase Uploads",
    path: "/media/limits",
    component: "Purchase",
    authenticated: true,
  },
  {
    title: "Sign In",
    path: "/signin",
    component: SignIn,
  },
  {
    title: "Sign Out",
    path: "/signout",
    component: "SignOut",
  },
  {
    title: "Register",
    path: "/signup",
    component: Register,
  },
  {
    title: "Account",
    path: "/account",
    component: "Account",
    authenticated: true,
  },
  {
    title: "Migration",
    path: "/migration",
    component: "Migration",
    authenticated: true,
  },
  {
    title: "Account",
    path: "/account/templates/associate",
    component: "AddKey",
    authenticated: true,
  },
  {
    title: "Forgot Password",
    path: "/forgotten-password",
    component: "ForgotPassword",
  },
  {
    title: "Change Password",
    path: "/change-password",
    component: "ChangePassword",
  },
  {
    title: "FAQs",
    path: "/faqs",
    component: "FAQs",
    authenticated: true,
  },
  {
    title: "Terms",
    path: "/terms",
    component: "Terms",
  },
  {
    title: "Not Found",
    path: "*",
    component: "NotFound",
  },
];
