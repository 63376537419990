import React, { useState, useRef, Fragment, useEffect } from "react";
import { Breadcrumbs, Button, Hero, LoadingOverlay } from "../../components";
import { useUpload, useMedia, useToken, useMediaLimit } from "../../hooks";
import { v4 as uuidv4 } from "uuid";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PhotographIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { Item } from "./Item";
import { ProgressItem } from "./ProgressItem";
import { getClient } from "../../utilities/pusher";
import { Link, useLocation } from "react-router-dom";
import { Success } from "../../components/Success";
import { useTranslation } from "react-i18next";

export const Media = () => {
  const upload = useUpload();
  const { token, user } = useToken();
  const { data: initialLimit, isSuccess: isLimitSuccess } = useMediaLimit();
  const { t } = useTranslation();

  const [inProgress, setInProgress] = useState([]);
  const [limit, setLimit] = useState(0);

  const fileInput = useRef();

  const [page, setPage] = useState(1);

  const {
    data: { images: media, previous, next } = { images: [] },
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useMedia(page);

  useEffect(() => {
    const pusher = getClient(token);
    const channel = pusher.subscribe(`private-${user.id}`);
    channel.bind("limit", setLimit);
  }, []);

  const location = useLocation();
  const [purchased, setPurchased] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has("purchase")) {
      setPurchased(true);
      setTimeout(() => {
        setPurchased(false);
      }, 5000);
    }

    if (
      query.has("page") &&
      Number.isInteger(Number.parseInt(query.get("page")))
    ) {
      setPage(query.get("page"));
    }
  }, [location]);

  useEffect(() => {
    if (isLimitSuccess) {
      setLimit(initialLimit);
    }
  }, [isLimitSuccess]);

  const handleMediaUpload = (e) => {
    if (e.target.files.length) {
      return Promise.all(
        Array.from(e.target.files).map((file) => {
          const id = uuidv4();
          setInProgress((inProgress) => [
            ...inProgress,
            { id, name: file.name, raw: file, progress: 0 },
          ]);

          const handleUploadProgress = (progress) =>
            setInProgress((inProgress) =>
              inProgress.map((file) =>
                file.id === id
                  ? {
                      ...file,
                      progress,
                    }
                  : file
              )
            );

          return upload({ file, onUploadProgress: handleUploadProgress }).then(
            () => {
              setInProgress((inProgress) =>
                inProgress.filter((file) => file.id !== id)
              );
              return refetch();
            }
          );
        })
      );
    }
  };

  return (
    <>
      <LoadingOverlay open={isLoading} />
      {purchased && (
        <Success>
          Thank you for your purchase. Your upload limit has been increased.
        </Success>
      )}
      <Breadcrumbs>
        <Breadcrumbs.Item to="#">{t("media.breadcrumb")}</Breadcrumbs.Item>
      </Breadcrumbs>
      <Hero title={t("heros.media.title")} subtitle={t("heros.media.subtitle")}>
        <div className="flex sm:flex-row items-center justify-end">
          <div className="flex items-center">
            <span className="flex items-center mr-2 ml-4 sm:ml-0 text-gray-600 font-medium text-sm h-5">
              <PhotographIcon className="w-5 h-5 mr-1" />
              {new Intl.NumberFormat("en-GB").format(limit)}
            </span>
            <span className="flex mr-2">
              <Link to="/media/limits">
                <Button variant="secondary">{t("heros.media.limit")}</Button>
              </Link>
            </span>
          </div>
          <div>
            <Button onClick={() => fileInput.current.click()}>
              {t("heros.media.files")}
            </Button>
          </div>
        </div>
      </Hero>
      <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto w-full flex-grow flex flex-col">
        <input
          className="hidden"
          type="file"
          onChange={handleMediaUpload}
          multiple
          ref={fileInput}
        />
        <div>
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {inProgress.map((file) => (
                <ProgressItem
                  key={file.id}
                  id={file.id}
                  name={file.name}
                  progress={file.progress}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-col flex-grow justify-between">
          {isError && (
            <div className="Home__error">
              We were unable to retrieve your media.{" "}
            </div>
          )}
          {isSuccess && (
            <>
              <ul
                role="list"
                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-10"
              >
                {media.map((file) => (
                  <Item
                    key={file.id}
                    url={file.url}
                    id={file.id}
                    refetch={refetch}
                    canDelete={file.canDelete}
                  />
                ))}
              </ul>
              {!media.length && (
                <div className="text-center mt-20">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No images
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by selecting some files.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => fileInput.current.click()}
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Choose Files
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          <nav
            className="relative z-0 inline-flex rounded-md -space-x-px py-8"
            aria-label="Pagination"
          >
            <button
              disabled={!previous}
              onClick={() => setPage(page - 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div
              aria-current="page"
              className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {page}
            </div>
            <button
              disabled={!next}
              onClick={() => setPage(page + 1)}
              className="relative inline-flex items-center px-2  py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </>
  );
};
