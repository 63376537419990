import React from "react";
import PropTypes from "prop-types";
import { CheckCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";

const Success = ({ children }) => {
  const classes = classNames({
    "bg-green-600": true,
    hidden: Boolean(children) === false,
  });
  return (
    <div className={classes}>
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-green-800">
              <CheckCircleIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white">{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Success.propTypes = {
  children: PropTypes.any,
};

export { Success };
