import "./LoadingOverlay.scss";
import Spinner from "../../assets/spinner.svg";
import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";

const LoadingOverlay = ({ open }) => {
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition-opacity duration-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="LoadingOverlay">
        <img className="lg:block h-20 w-auto" src={Spinner} alt="Loading" />
      </div>
    </Transition>
  );
};

LoadingOverlay.propTypes = {
  open: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  open: false,
};

export { LoadingOverlay };
