import axios from "axios";

export const getUser = ({ token, id }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);

export const updateUser = ({ token, id, firstName, lastName, username }) =>
  axios
    .patch(
      `${process.env.REACT_APP_API_URL}/api/users/${id}`,
      { firstName, lastName, username },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data);

export const addKey = ({ token, combination }) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/templates/add`,
      { combination },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data.success);
