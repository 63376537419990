import { useMutation } from "react-query";
import { getBundleIntent } from "../api";
import { useToken } from "./useToken";

export const useBundleIntent = () => {
  const mutation = useMutation(getBundleIntent);
  const { token } = useToken();

  const getBundleIntentPromise = (id) =>
    new Promise((resolve, reject) => {
      return mutation
        .mutateAsync({ token, id })
        .then((secret) => resolve(secret))
        .catch((error) => {
          reject(error);
        });
    });

  return {
    getBundleIntent: getBundleIntentPromise,
    isLoading: mutation.isLoading,
  };
};
