import "./SignIn.scss";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useError, useRedirect, useToken, useSignIn } from "../../hooks";
import { Error, Info, Input } from "../../components";

export const SignIn = () => {
  const { token, recentlyExpired } = useToken();
  const { redirect } = useRedirect();
  const { signIn, isLoading } = useSignIn();
  const navigate = useNavigate();
  const { error, setError } = useError();

  useEffect(() => {
    if (token && !redirect) {
      navigate("/");
    }
  }, [token, redirect]);

  const {
    handleChange,
    handleSubmit,
    values,
    isValid,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    isInitialValid: true,
    validate: ({ email, password }) => {
      let errors = {};

      if (!email.length) {
        errors.email = "Please provide an email address.";
      }

      if (!password.length) {
        errors.password = "Please provide a password.";
      }

      return errors;
    },
    onSubmit: ({ email, password }) =>
      signIn({ email, password }).catch(() => {
        setError(
          "We can't sign you in with those credentials. Please try again."
        );
      }),
  });

  const info = recentlyExpired
    ? "Your session expired. Please sign in again."
    : null;

  return (
    <>
      <Error data-test-id="error">{error}</Error>
      <Info>{info}</Info>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign In
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Manage your templates, configurations and media
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <Input
                  label="Email address"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  testId="input-email"
                />
              </div>

              <div>
                <Input
                  label="Password"
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && errors.password}
                  testId="input-password"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="mt-6 w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo"
                  disabled={(!isValid || isLoading) && "disabled"}
                  data-testid="button-submit"
                >
                  {isLoading && (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  )}
                  {isLoading ? "Signing in..." : "Sign in"}
                </button>
              </div>

              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <Link
                    to="/forgotten-password"
                    className="font-medium text-indigo-600 hover:text-indigo"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-center my-4">
            <a
              className="text-gray-500 hover:text-gray-800 text-sm underline"
              href="https://twitter.com/MonkeeTemplates"
              rel="noopener noreferrer"
              target="_blank"
            >
              Twitter
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
