export const navigation = (state, action) => {
  switch (action.type) {
    case "setShowMenu":
      return {
        ...state,
        showMenu: action.payload.showMenu,
      };
    default:
      return state;
  }
};
