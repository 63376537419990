import React from "react";
import PropTypes from "prop-types";

const Hero = ({ title, subtitle, children }) => {
  return (
    <header className="bg-white border-b border-grey-600 mb-10">
      <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div>
          <h1 className="text-xl font-bold text-gray-900">{title}</h1>
          {subtitle && <h2 className="text-gray-700">{subtitle}</h2>}
        </div>
        {children && <div className="mt-4 sm:mt-0">{children}</div>}
      </div>
    </header>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.any,
};

export { Hero };
