import axios from "axios";

export const getConfiguration = ({ token, id }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/configurations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);

export const updateConfiguration = ({ token, id, name, values }) =>
  axios
    .patch(
      `${process.env.REACT_APP_API_URL}/api/configurations/${id}`,
      { name, values },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data.success);

export const duplicateConfiguration = ({ token, id }) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/configurations/${id}/duplicate`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data.id);

export const deleteConfiguration = ({ token, id }) =>
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/configurations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.success);
