import { useContext } from "react";
import { AuthenticationContext } from "../contexts";
import {
  setRecentlyExpired as setRecentlyExpiredAction,
  setToken as setTokenAction,
} from "../actions";
import Cookies from "js-cookie";
import decode from "jwt-decode";

export const useToken = () => {
  const { state, dispatch } = useContext(AuthenticationContext);

  const setToken = (token) => {
    dispatch(setTokenAction(token));

    if (token) {
      Cookies.set("TOKEN", token);
    } else {
      Cookies.remove("TOKEN");
    }
  };

  const setRecentlyExpired = (expired) => {
    console.log("setting");
    dispatch(setRecentlyExpiredAction(expired));

    if (expired) {
      setTimeout(() => dispatch(setRecentlyExpiredAction(false)), 5000);
    }
  };

  return {
    token: state.token,
    user: state.token
      ? {
          ...decode(state.token).data,
          id: decode(state.token).sub,
        }
      : null,
    recentlyExpired: state.recentlyExpired,
    setToken,
    setRecentlyExpired,
  };
};
