import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Question } from "../components";

const getContentType = (node) => node.data.target.sys.contentType.sys.id;

const getFields = (node) => node.data.target.fields;

const getRenderer = (contentType) => {
  switch (contentType) {
    case "question":
      return Question;
    default:
      return null;
  }
};

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const contentType = getContentType(node);
      const fields = getFields(node);
      const Renderer = getRenderer(contentType);

      return Renderer ? <Renderer {...fields} /> : null;
    },
    [BLOCKS.HEADING_1]: (node) => {
      return (
        <h1 className="font-bold text-2xl mt-8 mb-4">
          {documentToReactComponents(node?.content?.[0])}
        </h1>
      );
    },
    [BLOCKS.HEADING_2]: (node) => {
      return (
        <h1 className="font-bold text-xl mt-2 mb-2">
          {documentToReactComponents(node?.content?.[0])}
        </h1>
      );
    },
    [BLOCKS.HEADING_3]: (node) => {
      return (
        <h1 className="font-bold text-lg mb-2">
          {documentToReactComponents(node?.content?.[0])}
        </h1>
      );
    },
    [BLOCKS.PARAGRAPH]: (node) => {
      console.log(node);
      return (
        <p className="mb-3">
          {node.content.map((item) => documentToReactComponents(item))}
        </p>
      );
    },
  },
};

export const render = (document) =>
  documentToReactComponents(document, options);
