import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

const Textarea = ({ label, value, onChange = () => {} }) => {
  const editorRef = useRef(null);
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={`mt-4 relative border border-gray-300 rounded-md pt-2 shadow-sm overflow ${
        focused && "ring-1 ring-indigo-600 border-indigo-600"
      }`}
    >
      <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
        {label}
      </label>
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onFocusIn={() => setFocused(true)}
        onFocusOut={() => setFocused(false)}
        value={value}
        onEditorChange={onChange}
        init={{
          height: 400,
          branding: false,
          menubar: "edit insert view format table tools",
          plugins: ["link image imagetools table spellchecker code lists"],
          toolbar_mode: "floating",
          toolbar1: "undo redo fontselect bullist numlist",
          toolbar2:
            "forecolor | alignleft aligncenter alignright | bold italic underline",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          skin: "CUSTOM",
          skin_url: "/skins/custom/skins/ui/CUSTOM",
        }}
      />
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export { Textarea };
