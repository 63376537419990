import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteConfiguration,
  getConfiguration,
  updateConfiguration,
} from "../api";
import { useToken } from "./useToken";
import { useNavigate } from "react-router-dom";

export const useConfiguration = (id) => {
  const { token } = useToken();
  const mutation = useMutation(updateConfiguration);
  const deleteMutation = useMutation(deleteConfiguration);
  const client = useQueryClient();
  const navigate = useNavigate();

  const query = useQuery(`getConfiguration:${id}`, () =>
    getConfiguration({ token, id }).catch(() => navigate("/not-found"))
  );

  return {
    getConfiguration: query,
    deleteConfiguration: () =>
      deleteMutation
        .mutateAsync({
          id,
          token,
        })
        .then(() =>
          client.invalidateQueries(`getTemplate:${query.data.template.id}`)
        ),
    updateConfiguration: ({ id, name, values }) =>
      mutation
        .mutateAsync({ id, token, name, values })
        .then(() =>
          Promise.all([
            client.invalidateQueries(`getTemplate${query.data.template.id}`),
            client.invalidateQueries(`getConfiguration:${id}`),
          ])
        ),
    isUpdateConfigurationLoading: mutation.isLoading,
  };
};
