import "./Page.scss";
import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Navigation } from "../Navigation";
import { LoadingOverlay } from "../LoadingOverlay";
import {
  usePageTitle,
  useToken,
  useRefreshAuthentication,
  useRedirectUnauthenticated,
} from "../../hooks";
import axios from "axios";

const Page = ({ title, authenticated, component }) => {
  usePageTitle(title);
  useRefreshAuthentication();
  useRedirectUnauthenticated(authenticated);

  const { token, setToken, setRecentlyExpired } = useToken();

  axios.interceptors.response.use(null, (error) => {
    if (error.response.status === 403) {
      setToken(null);
      setRecentlyExpired(true);
    }
  });

  if (authenticated && !token) {
    return <Navigation />;
  }

  const Component =
    typeof component === "string"
      ? React.lazy(() => import(`../../pages/${component}`))
      : component;

  return (
    <>
      <Navigation />
      <div className="flex flex-col flex-grow">
        <Suspense fallback={<LoadingOverlay open={true} />}>
          <Component />
        </Suspense>
      </div>
    </>
  );
};
Page.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  authenticated: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Page.defaultProps = {
  authenticated: false,
};

export { Page };
