import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Checkbox = ({ id, name, onChange, checked, error, children }) => {
  return (
    <div>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            name={name}
            onChange={onChange}
            type="checkbox"
            className={classNames({
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded": true,
              "border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
                !!error,
            })}
            defaultChecked={checked}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {children}
          </label>
        </div>
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

Checkbox.defaultProps = {
  id: "",
  name: "",
  checked: false,
  onChange: () => {},
  error: "",
};

export { Checkbox };
