import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  DeleteConfigurationModal,
  Error,
  Hero,
  LoadingOverlay,
} from "../../components";
import {
  useCreateTemplate,
  useDuplicateConfiguration,
  useTemplate,
} from "../../hooks";
import format from "date-fns/format";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/outline";
import formatDistance from "date-fns/formatDistance";
import { DuplicateIcon, TrashIcon } from "@heroicons/react/solid";

export const Template = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: template,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useTemplate(id);
  const createConfiguration = useCreateTemplate(id);
  const duplicateConfiguration = useDuplicateConfiguration();

  const [open, setOpen] = useState(false);
  const [configuration, setConfiguration] = useState({});

  const handleCreateConfiguration = () =>
    createConfiguration().then((id) =>
      refetch().then(() => navigate(`/configuration/${id}`))
    );

  const handleDuplicateConfiguration = (id) =>
    duplicateConfiguration(id).then((id) =>
      refetch().then(() => navigate(`/configuration/${id}`))
    );

  return (
    <>
      <LoadingOverlay open={isLoading} />
      <DeleteConfigurationModal
        open={open}
        setOpen={setOpen}
        configuration={configuration}
        onDelete={refetch}
      />
      {isError && <Error>We were unable to retrieve this template.</Error>}
      {isSuccess && (
        <div className="pb-4">
          <Breadcrumbs>
            <Breadcrumbs.Item to={"#"}>{template.name}</Breadcrumbs.Item>
          </Breadcrumbs>
          <Hero
            title={template.name}
            subtitle={`Purchased on ${format(
              new Date(template.purchasedAt),
              "do LLL yyyy"
            )}`}
          >
            <Button onClick={handleCreateConfiguration}>
              New Configuration
            </Button>
          </Hero>
          <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto w-full">
            <ul
              role="list"
              className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
            >
              {template.configurations.map((configuration) => (
                <li
                  key={configuration.id}
                  className="col-span-1 block bg-white rounded-lg shadow divide-y divide-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-gray-20"
                >
                  <Link to={`/configuration/${configuration.id}`}>
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div
                        className="flex items-center justify-center w-10 h-10 rounded-full flex-shrink-0 text-sm font-bold bg-gray-200"
                        style={{
                          backgroundColor:
                            configuration?.values?.colours?.accentTwo,
                          color: configuration?.values?.colours?.promoFg,
                        }}
                      >
                        {configuration.name
                          .split(" ")
                          .map((words) =>
                            words.length > 0 ? words[0].toUpperCase() : ""
                          )
                          .slice(0, 2)
                          .join("")}
                      </div>
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-gray-900 text-sm font-bold truncate">
                            {configuration.name}
                          </h3>
                        </div>
                        <p className="mt-1 text-gray-500 text-sm truncate">
                          Created{" "}
                          <time>
                            {formatDistance(
                              new Date(configuration.createdAt),
                              new Date(),
                              { addSuffix: true }
                            )}
                          </time>
                        </p>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="w-0 flex-1 flex">
                        <button
                          onClick={() =>
                            handleDuplicateConfiguration(configuration.id)
                          }
                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                          <DuplicateIcon className="w-5 h-5 text-gray-400" />
                          <span className="ml-3">Duplicate</span>
                        </button>
                      </div>
                      <div className="-ml-px w-0 flex-1 flex">
                        <button
                          onClick={() => {
                            setConfiguration(configuration);
                            setOpen(true);
                          }}
                          className="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                        >
                          <TrashIcon className="w-5 h-5 text-gray-400" />
                          <span className="ml-3">Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {!template.configurations.length && (
              <div className="text-center mt-20">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No configurations
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by creating a configuration.
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleCreateConfiguration}
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    New Configuration
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
