import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./index.css";
import App from "./App";
import "./i18n";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_COMMIT_HASH,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
