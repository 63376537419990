import { useContext } from "react";
import { AuthenticationContext } from "../contexts";
import { setRedirect as setRedirectAction } from "../actions";

export const useRedirect = () => {
  const { state, dispatch } = useContext(AuthenticationContext);
  const setRedirect = (redirect) => dispatch(setRedirectAction(redirect));

  return { redirect: state.redirect, setRedirect };
};
