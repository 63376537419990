import React from "react";
import PropTypes from "prop-types";

const ProgressItem = ({ id, name, progress }) => {
  return (
    <li key={id}>
      <div className="block">
        <div className="px-4 py-4 flex items-center sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="truncate">
              <div className="flex flex-col text-sm">
                <p className="font-medium text-indigo-600 truncate">{name}</p>
              </div>
            </div>
            <div className="flex items-center flex-grow w-[50%] flex-shrink-0 sm:mt-0 sm:ml-5 max-w-sm-[50%]">
              <div className="relative h-2 w-full flex-grow-1 mr-4">
                <div className="h-full w-full bg-gray-200 rounded-md" />
                <div
                  className="absolute left-0 top-0 h-full bg-indigo-600 rounded-md transition-all"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <div className="flex overflow-hidden -space-x-1 flex-shrink-0 font-medium">
                {progress}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

ProgressItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

export { ProgressItem };
