import "./Home.scss";
import React, { useEffect } from "react";
import { Hero, LoadingOverlay } from "../../components";
import { useAccount, useTemplates, useToken } from "../../hooks";
import { BadgeCheckIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import differenceInDays from "date-fns/differenceInDays";
import formatDistance from "date-fns/formatDistance";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const navigate = useNavigate();
  const { data: templates, isLoading, isError, isSuccess } = useTemplates();
  const {
    user: {
      data: userData,
      isLoading: isUserLoading,
      isSuccess: isUserSuccess,
    },
  } = useAccount();
  const { user } = useToken();
  const { t } = useTranslation();

  useEffect(() => {
    if (isUserSuccess && userData?.migration?.nameChecked === false) {
      navigate("/migration");
    }
  }, [isUserSuccess, userData]);

  return (
    <>
      <LoadingOverlay open={isLoading || isUserLoading} />
      <Hero
        title={t("heros.templates.title")}
        subtitle={t("heros.templates.subtitle")}
      >
        <div className="flex">
          <div className="rounded-xl bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BadgeCheckIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-800">
                  {t("heros.templates.licensed")}{" "}
                  <span className="font-medium">{user.username}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto w-full">
        <div className="Home__templates">
          {isError && (
            <div className="Home__error">
              We were unable to retrieve your templates.{" "}
            </div>
          )}
          {isSuccess && (
            <div className="sm:rounded-md pb-4">
              <ul
                role="list"
                className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
              >
                {templates.map((template) => {
                  const daysSincePurchased = differenceInDays(
                    new Date(),
                    new Date(template.purchasedAt)
                  );

                  const isNew = daysSincePurchased <= 7;

                  return (
                    <li key={template.id} className="col-span-1">
                      <Link
                        className="block bg-white rounded-lg shadow divide-y divide-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-gray-20"
                        to={`/template/${template.id}`}
                      >
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                          <div
                            className={`flex items-center justify-center w-10 h-10 text-white rounded-full flex-shrink-0 text-sm font-bold`}
                            style={{ backgroundColor: template.colour.hex }}
                          >
                            {template.name
                              .split(" ")
                              .map((words) => words[0].toUpperCase())
                              .join("")}
                          </div>
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 text-sm font-bold truncate">
                                {template.name}
                              </h3>
                              {isNew && (
                                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                  {t("templates.new")}
                                </span>
                              )}
                            </div>
                            <p className="mt-1 text-gray-500 text-sm truncate">
                              {t("templates.added")}{" "}
                              <time>
                                {formatDistance(
                                  new Date(template.purchasedAt),
                                  new Date(),
                                  { addSuffix: true }
                                )}
                              </time>
                            </p>
                          </div>
                          <div>
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
