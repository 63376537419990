import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { Button, LoadingOverlay } from "../../components";
import { ProgressItem } from "../Media/ProgressItem";
import { Item } from "./Item";
import { useMedia, useMediaLimit, useToken, useUpload } from "../../hooks";
import { getClient } from "../../utilities/pusher";
import { v4 as uuidv4 } from "uuid";

const Media = ({ open, setOpen, onSelect, logo }) => {
  const upload = useUpload();
  const { token, user } = useToken();
  const { data: initialLimit, isSuccess: isLimitSuccess } = useMediaLimit();

  const [inProgress, setInProgress] = useState([]);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(1);
  const [height, setHeight] = useState(70);

  const fileInput = useRef();

  const {
    data: { images: media, previous, next } = { images: [] },
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useMedia(page);

  useEffect(() => {
    const pusher = getClient(token);
    const channel = pusher.subscribe(`private-${user.id}`);
    channel.bind("limit", setLimit);
  }, []);

  useEffect(() => {
    if (isLimitSuccess) {
      setLimit(initialLimit);
    }
  }, [isLimitSuccess]);

  const handleMediaUpload = (e) => {
    if (e.target.files.length) {
      return Promise.all(
        Array.from(e.target.files).map((file) => {
          const id = uuidv4();
          setInProgress((inProgress) => [
            ...inProgress,
            { id, name: file.name, raw: file, progress: 0 },
          ]);

          const handleUploadProgress = (progress) =>
            setInProgress((inProgress) =>
              inProgress.map((file) =>
                file.id === id
                  ? {
                      ...file,
                      progress,
                    }
                  : file
              )
            );

          return upload({ file, onUploadProgress: handleUploadProgress }).then(
            () => {
              setInProgress((inProgress) =>
                inProgress.filter((file) => file.id !== id)
              );
              return refetch();
            }
          );
        })
      );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="py-6 px-4 bg-gray-50 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium">
                        Your Media
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-gray-500">
                        You&apos;ve got{" "}
                        <span className="text-black underline font-medium">
                          {new Intl.NumberFormat("en-GB").format(limit)}
                        </span>{" "}
                        uploads remaining.
                      </p>
                    </div>
                  </div>

                  <div className="mt-6 relative flex-1 px-4 sm:px-4">
                    {/* Replace with your content */}
                    <LoadingOverlay open={isLoading} />
                    {!isLoading && (
                      <>
                        <div className="max-w-7xl px-2 mx-auto w-full">
                          <div className="flex justify-between items-center">
                            <div>
                              <Button onClick={() => fileInput.current.click()}>
                                Choose files
                              </Button>
                              <input
                                className="hidden"
                                type="file"
                                onChange={handleMediaUpload}
                                multiple
                                ref={fileInput}
                              />
                            </div>
                            {logo && (
                              <div>
                                <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                                  <label className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">
                                    Max Height (pixels)
                                  </label>
                                  <input
                                    type="text"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-md">
                              <ul
                                role="list"
                                className="divide-y divide-gray-200"
                              >
                                {inProgress.map((file) => (
                                  <ProgressItem
                                    key={file.id}
                                    id={file.id}
                                    name={file.name}
                                    progress={file.progress}
                                  />
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div>
                            {isError && (
                              <div className="Home__error">
                                We were unable to retrieve your media.{" "}
                              </div>
                            )}
                            {isSuccess && (
                              <>
                                <ul
                                  role="list"
                                  className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 mt-10"
                                >
                                  {media.map((file) => (
                                    <Item
                                      key={file.id}
                                      url={file.url}
                                      id={file.id}
                                      refetch={refetch}
                                      onClick={(url) => onSelect(url, height)}
                                      canDelete={file.canDelete}
                                    />
                                  ))}
                                </ul>
                                {!media.length && (
                                  <div className="text-center mt-10">
                                    <svg
                                      className="mx-auto h-12 w-12 text-gray-400"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                      />
                                    </svg>
                                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                                      No images
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                      Get started by selecting some files.
                                    </p>
                                    <div className="mt-6">
                                      <button
                                        type="button"
                                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() =>
                                          fileInput.current.click()
                                        }
                                      >
                                        <PlusIcon
                                          className="-ml-1 mr-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                        Choose Files
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <nav
                                  className="relative z-0 inline-flex rounded-md -space-x-px py-8"
                                  aria-label="Pagination"
                                >
                                  <button
                                    disabled={!previous}
                                    onClick={() => setPage(page - 1)}
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                  >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                  <div
                                    aria-current="page"
                                    className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                  >
                                    {page}
                                  </div>
                                  <button
                                    disabled={!next}
                                    onClick={() => setPage(page + 1)}
                                    className="relative inline-flex items-center px-2  py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                  >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </nav>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Media.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  logo: PropTypes.bool,
};

export { Media };
