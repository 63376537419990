import { useMutation } from "react-query";
import { deleteConfiguration } from "../api";
import { useToken } from "./useToken";

export const useDeleteConfiguration = () => {
  const { token } = useToken();
  const deleteMutation = useMutation(deleteConfiguration);

  return (id) =>
    deleteMutation.mutateAsync({
      id,
      token,
    });
};
