import React, { Fragment, useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";

function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const Colour = ({ label, value, onChange = () => {} }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOutsideAlerter(ref, () => {
    setOpen(false);
  });

  return (
    <div className="flex justify-between align-center mt-2 relative rounded-md px-1 py-2">
      <label className="inline-block px-1 bg-white text-sm font-medium text-gray-900 mt-1">
        {label}
      </label>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="w-6 h-6 rounded-md ring-1 ring-gray-400 focus-within:ring-2 focus-within:ring-indigo-600 focus-within:border-indigo-600 ring-offset-2"
        style={{ backgroundColor: value }}
      />
      <div ref={ref} className="top-full absolute right-0 z-50">
        <Transition.Root show={open} as={Fragment}>
          <div>
            <SketchPicker
              color={value}
              onChange={(color) => onChange(color.hex)}
            />
          </div>
        </Transition.Root>
      </div>
    </div>
  );
};

Colour.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export { Colour };
