import { useState } from "react";

export const useInfo = () => {
  const [info, setInfo] = useState(null);
  let timeoutId = null;

  const handleSetInfo = (info, timeout = 5000) => {
    if (timeoutId) {
      timeoutId = null;
    }

    setInfo(info);
    timeoutId = setTimeout(() => {
      setInfo(null);
    }, timeout);
  };

  return { info, setInfo: handleSetInfo };
};
