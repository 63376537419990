import React from "react";
import PropTypes from "prop-types";
import { render } from "../../contentful";

const Question = ({ question, answer }) => {
  return (
    <div className="my-2 mb-8">
      <h2 className="text-lg leading-6 font-bold text-gray-900 mb-2">
        {question}
      </h2>
      <div className="text-gray-900">{render(answer)}</div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.object.isRequired,
};

export { Question };
