import axios from "axios";

export const getBundles = ({ token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/bundles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.bundles);

export const getBundleIntent = ({ token, id }) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/bundles/${id}/intent`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.secret);
