import "./App.scss";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { Page } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthenticationContext, NavigationContext } from "./contexts";
import { useAuthentication, useNavigation } from "./hooks";

const queryClient = new QueryClient();

function App() {
  const { contextValue: authenticationContextValue } = useAuthentication();
  const { contextValue: navigationContextValue } = useNavigation();

  return (
    <QueryClientProvider client={queryClient}>
      <NavigationContext.Provider value={navigationContextValue}>
        <AuthenticationContext.Provider value={authenticationContextValue}>
          <div className="App bg-gray-50">
            <BrowserRouter>
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <Page
                        title={route.title}
                        path={route.path}
                        authenticated={route.authenticated}
                        component={route.component}
                      />
                    }
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </div>
        </AuthenticationContext.Provider>
      </NavigationContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
