import axios from "axios";

export const getTemplates = ({ token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/templates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.templates);

export const getTemplate = ({ token, id }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/templates/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);

export const createTemplate = ({ token, id }) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/templates/${id}/configuration/new`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data.id);
