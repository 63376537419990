import { useMutation } from "react-query";
import { resetPassword, changePassword } from "../api";

export const useResetPassword = () => {
  const resetMutation = useMutation(resetPassword);
  const changeMutation = useMutation(changePassword);

  return {
    isResetPasswordLoading: resetMutation.isLoading,
    resetPassword: (email) => resetMutation.mutateAsync(email),
    isChangePasswordLoading: changeMutation.isLoading,
    changePassword: ({ token, password }) =>
      changeMutation.mutateAsync({ token, password }),
  };
};
