import axios from "axios";
import { useQuery } from "react-query";
import { useToken } from "./useToken";

export const useContent = (key) => {
  const { token } = useToken();
  return useQuery("getFAQs", () =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/content?id=${key}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
  );
};
