import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";

const Breadcrumbs = ({ children }) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto w-full pt-4 z-100">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="h-5 w-5" />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {children}
          </ol>
        </nav>
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.any.isRequired,
};

const Item = ({ to, children }) => (
  <li>
    <div className="flex items-center">
      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
      <Link
        to={to}
        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
        aria-current="page"
      >
        {children}
      </Link>
    </div>
  </li>
);

Item.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
};

Breadcrumbs.Item = Item;

export { Breadcrumbs };
