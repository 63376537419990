import React from "react";
import PropTypes from "prop-types";
import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { InformationCircleIcon } from "@heroicons/react/solid";

const Info = ({ children }) => {
  const classes = classNames({
    "bg-blue-600": true,
    hidden: Boolean(children) === false,
  });
  return (
    <div className={classes}>
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-blue-800">
              <InformationCircleIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white">{children}</p>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  children: PropTypes.any,
};

export { Info };
