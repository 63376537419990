import { useState } from "react";

export const useError = () => {
  const [error, setError] = useState(null);
  let timeoutId = null;

  const handleSetError = (error, timeout = 5000) => {
    if (timeoutId) {
      timeoutId = null;
    }

    setError(error);
    timeoutId = setTimeout(() => {
      setError(null);
    }, timeout);
  };

  return { error, setError: handleSetError };
};
