import axios from "axios";

export const register = ({
  firstName,
  lastName,
  email,
  username,
  password,
  combination,
}) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/registration`,
      {
        firstName,
        lastName,
        email,
        username,
        password,
        combination,
      },
      { validateStatus: (status) => [200, 400].includes(status) }
    )
    .then((response) => {
      if (response && response.status !== 200) {
        throw new Error(response.data.error.message);
      }
      return true;
    });
