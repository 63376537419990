import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { useDeleteUpload } from "../../hooks";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const Item = ({ id, url, refetch, canDelete }) => {
  const deleteUpload = useDeleteUpload();
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const menu = [
    {
      name: t("media.delete"),
      onClick: () => deleteUpload({ id }).then(refetch),
    },
  ];

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <li className="relative">
      <div className="group w-full h-[10rem] sm:h-[15rem] rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 overflow-hidden flex items-center justify-center">
        <img src={url} alt="" className="block max-h-full max-w-full" />
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
            PNG
          </p>
        </div>
        <div className="flex">
          <div>
            <CopyToClipboard text={url}>
              <button
                className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-800 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1"
                onClick={handleCopy}
              >
                <span className="sr-only">Copy URL</span>
                {copied ? (
                  <ClipboardCheckIcon
                    className="w-5 h-5 text-green-600 transition-all"
                    aria-hidden="true"
                  />
                ) : (
                  <ClipboardCopyIcon
                    className="w-5 h-5 transition-all"
                    aria-hidden="true"
                  />
                )}
              </button>
            </CopyToClipboard>
          </div>
          {canDelete && (
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {menu.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <div
                          onClick={item.onClick}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 break-all"
                          )}
                        >
                          {item.name}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
    </li>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
};

export { Item };
