import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputMask from "react-input-mask";

const MaskedInput = ({
  label,
  id,
  name,
  mask,
  type,
  autoComplete,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1">
        <InputMask
          mask={mask}
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          maskChar="_"
        >
          {(props) => (
            <input
              {...props}
              className={classNames({
                "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo focus:border-indigo sm:text-sm": true,
                "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
                  !!error,
              })}
            />
          )}
        </InputMask>
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
};

MaskedInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

MaskedInput.defaultProps = {
  label: "",
  id: "",
  name: "",
  type: "text",
  autoComplete: "",
  value: "",
  onChange: () => {},
  onBlur: () => {},
  error: "",
};

export { MaskedInput };
