import { useMutation } from "react-query";
import { signIn } from "../api";
import { useRedirect } from "./useRedirect";
import { useNavigate } from "react-router-dom";
import { useToken } from "./useToken";

export const useSignIn = () => {
  const navigate = useNavigate();
  const { redirect, setRedirect } = useRedirect();
  const { setToken } = useToken();
  const mutation = useMutation(signIn);

  const signInPromise = ({ email, password }) =>
    new Promise((resolve, reject) => {
      return mutation
        .mutateAsync({ email, password })
        .then((token) => {
          setToken(token);

          if (redirect) {
            navigate(redirect);
            setRedirect(null);
          } else {
            navigate("/");
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

  return { signIn: signInPromise, isLoading: mutation.isLoading };
};
