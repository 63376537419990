import { useToken } from "./useToken";
import decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { refreshToken } from "../api";

const refreshWindow = 60 * 15;

export const useRefreshAuthentication = () => {
  const location = useLocation();
  const { token, setToken } = useToken();

  useEffect(async () => {
    if (!token) {
      return;
    }

    const { exp } = decode(token);
    const expiryTime = new Date(exp * 1000);
    const currentTime = new Date();

    const secondsRemaining = Math.floor(
      expiryTime.getTime() - currentTime.getTime()
    );

    if (secondsRemaining <= 0) {
      setToken(null);
      return;
    }

    if (secondsRemaining <= refreshWindow) {
      await refreshToken({ token }).then(setToken);
    }
  }, [location.pathname]);
};
