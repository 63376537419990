export const setToken = (token) => ({
  type: "setToken",
  payload: { token },
});

export const setRedirect = (redirect) => ({
  type: "setRedirect",
  payload: { redirect },
});

export const setRecentlyExpired = (expired) => ({
  type: "setRecentlyExpired",
  payload: { expired },
});
