import { useMemo, useReducer } from "react";
import { navigation } from "../reducers";

const initialState = {
  showMenu: false,
};

export const useNavigation = () => {
  const [state, dispatch] = useReducer(navigation, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return {
    state,
    dispatch,
    contextValue,
  };
};
