import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Button = ({ variant, type, disabled, onClick, children, testId }) => {
  const classes = classNames({
    "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2": true,
    "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500":
      variant === "secondary",
    "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500":
      variant === "primary",
  });

  return (
    <button
      className={classes}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired,
  testId: PropTypes.string,
};

Button.defaultProps = {
  variant: "primary",
  type: "button",
  disabled: false,
  onClick: () => {},
};

export { Button };
