import { useToken } from "./useToken";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRedirect } from "./useRedirect";

export const useRedirectUnauthenticated = (requiresAuthentication) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useToken();
  const { setRedirect } = useRedirect();

  return useEffect(() => {
    if (requiresAuthentication && !token) {
      setRedirect(location.pathname);
      navigate("/signin");
    }
  }, [requiresAuthentication, token]);
};
