import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { useToken } from "../../hooks";
import { routes } from "../../routes";
import Logo from "../../assets/logo.svg";
import WideLogo from "../../assets/wide-logo.svg";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const badges = {
  development: {
    text: "text-gray-800",
    background: "bg-gray-100",
  },
  staging: {
    text: "text-indigo-800",
    background: "bg-indigo-100",
  },
};

export const Navigation = () => {
  const { token: isAuthenticated, user } = useToken();
  const location = useLocation();
  const { t } = useTranslation();

  const badge = badges[process.env.REACT_APP_ENV];
  const initials = `${user?.firstName?.[0] || ""}${user?.lastName?.[0] || ""}`;

  const navigation = [
    { name: t("navigation.templates"), to: "/" },
    { name: t("navigation.media"), to: "/media" },
    { name: t("navigation.help"), to: "/faqs" },
  ];

  const userNavigation = [
    {
      name: t("navigation.account"),
      to: "/account",
      testId: "account",
    },
    {
      name: t("navigation.licence"),
      to: "/account/templates/associate",
      testId: "associate",
    },
    {
      name: t("navigation.signout"),
      to: "/signout",
      testId: "sign-out",
    },
  ];

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="block lg:hidden h-12 w-auto"
                        src={Logo}
                        alt="Workflow"
                      />
                      <img
                        className="hidden lg:block h-8 w-auto"
                        src={WideLogo}
                        alt="Workflow"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation
                      .filter((link) =>
                        isAuthenticated
                          ? true
                          : !routes.find((route) => route.path === link.to)
                              .authenticated
                      )
                      .map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            location.pathname === item.to
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                          data-testid={item.testId && `link-${item.testId}`}
                        >
                          {item.name}
                        </Link>
                      ))}
                  </div>
                </div>
                {isAuthenticated && (
                  <>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                      {!!badge && (
                        <span
                          className={`inline-flex items-center px-3 py-0.5 rounded text-sm font-medium ${badge.text} ${badge.background}`}
                        >
                          {process.env.REACT_APP_ENV}
                        </span>
                      )}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button
                            className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            data-testid="button-menu"
                          >
                            <span className="sr-only">Open user menu</span>
                            <div className="h-8 w-8 rounded-full bg-gray-600 flex justify-center items-center">
                              <span className="text-xs font-bold text-white">
                                {initials}
                              </span>
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.to}>
                                {({ active }) => (
                                  <Link
                                    to={item.to}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                    data-testid={
                                      item.testId && `link-${item.testId}`
                                    }
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    {isAuthenticated && (
                      <div className="-mr-2 flex items-center sm:hidden">
                        {!!badge && (
                          <span
                            className={`mr-2 inline-flex items-center px-3 py-0.5 rounded text-sm font-medium ${badge.text} ${badge.background}`}
                          >
                            {process.env.REACT_APP_ENV}
                          </span>
                        )}
                        <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={Link}
                    to={item.to}
                    className={classNames(
                      item.current
                        ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                    data-testid={item.testId && `link-${item.testId}`}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              {isAuthenticated && (
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 rounded-full bg-gray-600 flex justify-center items-center">
                        <span className="text-xs font-bold text-white">
                          {initials}
                        </span>
                      </div>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {user.firstName} {user.lastName}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as={Link}
                        to={item.to}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                        data-testid={item.testId && `link-${item.testId}`}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
