import axios from "axios";

export const signIn = ({ email, password }) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/signin`, {
      email,
      password,
    })
    .then((response) => response.data.token);

export const checkToken = ({ token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/check`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => true)
    .catch(() => false);

export const refreshToken = ({ token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.token);

export const signOut = ({ token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/signout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => true)
    .catch(() => false);

export const resetPassword = (email) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/password/reset`, {
      email,
    })
    .then(() => true)
    .catch(() => false);

export const changePassword = ({ token, password }) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/password/change`, {
      token,
      password,
    })
    .then(() => true)
    .catch(() => false);
